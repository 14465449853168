import {Link, graphql, withPrefix} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {ReactComponent as Logo} from '../assets/design/logo-alt.svg';
import window from '../assets/images/window-1.jpg';
import Guarantee from '../components/guarantee';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Service from '../components/service';
import StyledButton from '../components/styledButton';
import '../css/markdown.css';
import solidifiedRemainsSm from "../assets/video/solidified-remains-480.mp4";
import solidifiedRemainsMd from "../assets/video/solidified-remains-720.mp4";
import solidifiedRemainsLg from "../assets/video/solidified-remains-1080.mp4";
import solidifiedRemainsThumb from "../assets/video/solidified-remains-thumb.png";
import Video from "../components/video";

const solidifiedRemainsVideo = {
    sm: solidifiedRemainsSm,
    md: solidifiedRemainsMd,
    lg: solidifiedRemainsLg,
    thumb: solidifiedRemainsThumb,
};

const ServicePage = ({data, pageContext, id, video = null}) => {
    console.log("data, context, id, video", data, pageContext, id, video)
    const service = data.service;
    const title = service.frontmatter.title;
    const image = service.frontmatter.image;
    const icon = service.frontmatter.icon;
    const blurb = service.frontmatter.blurb;
    const price = service.frontmatter.price;
    const excerpt = service.excerpt;

    const services = data.services.edges;

    return (
        <Layout
            headerClass='relative bg-white'
            bodyClass='px-0 md:px-0 lg:px-0'
            hasHero={false}
            heroImage={withPrefix(image)}
        >
            <SEO
                title={`Affordable ${title}`}
                description={`City of Oaks Funeral Home and Cremation provides affordable ${title} starting at ${price}`}
            />
            <div className='flex flex-col mt-6'>
                <div className='w-24 h-24 self-center'>
                    <Logo/>
                </div>
                <div className='text-center'>
                    <h2 className='text-xl md:text-2xl uppercase text-gray-700'>
                        City of Oaks Funeral Home & Cremations
                    </h2>
                </div>
            </div>

            <div className='container mx-auto px-6 md:px-10 lg:px-24 pt-8 '>
                <div className='flex flex-col'>
                    <div className='markdown-body py-6 text-lg'>
                        <h1 className='text-gray-700 text-sm'>
                            City of Oaks Funeral Home and Cremation provides an affordable{' '}
                            {title.toLowerCase()}
                        </h1>
                        <div dangerouslySetInnerHTML={{__html: service.html}}></div>
                    </div>
                    {data.service.frontmatter.path === "/services/cremation-with-solidified-remains" && (
                        <Video video={solidifiedRemainsVideo}/>
                    )}
                    {video && (
                        <>
                            <div className='w-full mt-6'>
                                <video
                                    style={{background: `#4e5458`}}
                                    poster={video.thumb}
                                    controls
                                >
                                    <source src={video.lg}/>
                                </video>
                            </div>
                        </>
                    )}
                    <p className='text-xl pt-8 pb-8'>
                        We assist families all throughout North Carolina and we do{' '}
                        <strong>not</strong> charge an additional mileage fee.
                    </p>
                    <div className='self-center mb-10'>
                        <Link to='/contact/'>
                            <StyledButton>Find out more</StyledButton>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='flex flex-wrap justify-center items-stretch -mx-2'>
                <p className='mb-2 text-4xl text-gray-800 self-center'>Our Services</p>
                <div className='flex flex-wrap justify-center items-stretch -mx-2'>
                    {services.map(({node}) => (
                        <Service
                            title={node.frontmatter.title}
                            slug={node.frontmatter.slug}
                            url={node.frontmatter.path}
                            key={node.frontmatter.path}
                            icon={node.frontmatter.icon}
                            price={node.frontmatter.price}
                            blurb={node.frontmatter.blurb}
                        />
                    ))}
                </div>

                <div className='self-center my-4'>
                    <Link to='/services/'>
                        <StyledButton>View all services</StyledButton>
                    </Link>
                </div>
                <Guarantee/>
            </div>
        </Layout>
    );
};

export const query = graphql`
  query ($id: String) {
    service: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        icon
        image
        price
        blurb
      }
      html
    }
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            path
            icon
            price
            blurb
          }
          excerpt
        }
      }
    }
  }
`;
export default ServicePage;
